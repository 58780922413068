$(document).on("turbolinks:load", function() {
  if ( document.querySelector('body.template_inspection_reports') ) {

    hideFields();
    updateTemplateCategoryOptions();

    $('.nested-fields').each(function() {
      updateTemplateCategoryOptions(this);
    })

    function hideFields() {
      $("input[id$='has_subquestions']").each(function() {
        if ( !$(this).prop("checked") ) {
          $(this).closest('.form-group').next('.form-group').hide();
        };
      });

      $("select[id$='response_type']").each(function() {
        if (this.value == "Select" || this.value == "MultiSelect") {
          $(this).closest('.nested-fields').find('.template_inspection_report_template_questions_auto_populate_attribute').hide();
          $(this).closest('.nested-fields').find('.template_inspection_report_template_questions_auto_populate_only').hide();
        } else {
          $(this).closest('.nested-fields').find('.template_inspection_report_template_questions_response_options').hide();
        };
      });

      $("input[id$='has_action_items']").each(function() {
        if ( !$(this).prop("checked") ) {
          $(this).closest('.form-group').next('.form-group').hide();
        };
      });
    }

    function updateTemplateCategoryOptions(element) {
      var inspection_type = $(document).find("#template_inspection_report_inspection_type").val();
      var options = []
      switch(inspection_type) {
        case "SWPP":
          options = [
            "General Information",
            "Administrative Requirements",
            "Permit Coverage",
            "Stormwater Pollution Prevention Plan (SWPPP)",
            "Site Conditions",
            "Site Status",
            "Stormwater Management Facilities (including Basins), Outfalls, and Discharges",
            "Erosion and Sediment Control BMPs"
          ]
          break;
        case "Dust":
          options = [
            "General Information",
            "Administrative Requirements",
            "Communication",
            "Hauling",
            "Stabilization",
            "Track Out"
          ]
          break;
        case "Safety":
          options = [
            "General Information",
            "Administrative Requirements",
            "Jobsite/Construction Trailer",
            "PPE",
            "Electrical",
            "Tools",
            "Scaffolding",
            "Ladders",
            "Excavation",
            "Housekeeping",
            "Equipment Operation",
            "Confinded Space Entry",
            "Lock Out/Tag Out"
          ]
          break;
        case "QC":
          options = [
            "General Information",
            "SWPPP General",
            "SWMP General",
            "Site Conditions",
            "ERX",
            "Maps",
            "Active Site Map",
            "Logs",
            "Dust Control",
            "Communication with Client",
            "Client Specific"
          ]
          break;
        case "Daily Log":
          options = [
            "General Information",
            "General Information - Site",
            "General Information - Inspector",
            "General Information - Inspection",
            "Inspection Items"
          ]
          break;
        case "Document":
          options = [
            "None",
            "General Information",
            "Site Information",
            "Discharge Information",
            "Regulatory Reporting Information",
            "Performance Criteria - Administrative Requirements",
            "Performance Criteria - Stormwater Management Facilities",
            "Performance Criteria - Erosion and Sediment Control BMPs",
            "Performance Criteria - Construction Activity",
            "Performance Criteria - Previous 3-Month Performance",
            "Division Stormwater Compliance Representative (DSCR) QCIR Review",
            "Region Stormwater Compliance Representative (RSCR) QCIR Review",
            "Corporate Stormwater Compliance Representative (CSCR) CSIR Review",
            "Potential Material Failures",
            "General Description of Potential Material Failure",
            "Quarterly Summary Report - Data Collection",
            "General",
            "Front Cover",
            "Section 1.0 Site Details",
            "Section 2.0 SWPPP Narative",
            "Section 3.0 SWPPP Stormwater Team and Certifications",
            "Section 4.0 Regulatory Information",
            "Section 5.0 Wetlands",
            "Section 6.0 Other Regulatory Permit / Review Information",
            "Section 7.0 Best Management Practices",
            "Section 8.0 Spill Prevention Control and Countermeasure",
            "Section 9.0 Site Plans",
            "Section 10.0 Training Records & Qualified Person(s) Information",
            "Section 11.0 NPDES Inspections, Maintanance, & Corrective Actions",
            "Section 12.0 Logbooks",
            "Section 13.0 SWPPP Amendments, Updates, and Modifications",
            "Section 14.0 Regulatory Records (letters, emails, phone logs, etc.)",
            "Section 15.0 Stormwater Management or Erosion & Sediment Control Publication(s)",
            "Section 16.0 Stormwater Management System Records",
            "Project Information",
            "Land Development",
            "Finished Lot or Infill Finished Lot Purchases",
            "Permit Coverage",
            "Stormwater Management Facilities (including Basins), Outfalls & Discharges",
            "Erosion and Sediment Control BMPs",
            "Pre-Acquisition Inspection & Review",
            "Finished Lot or Infill Finished Lot Purchases",
            "National Pollutant Discharge Elimination System - Construction General Permit & SWPPP",
            "SWPPP Review(s) / Certification(s) / Designation(s)",
            "SWPPP Narative",
            "Plan Sheets",
            "Standard Details within Engineering Plans",
            "Pre-Construction Preparation",
            "NPDES Inspection Reports & Corrective Actions",
            "NPDES CGP Impaired or Special Designation Requirements",
            "Final Stabilization - Notice of Termination",
            "Location of Spill Incident",
            "Cause of Spill",
            "Affected Areas",
            "Regulatory Notification",
            "MetaCat CCIR - Part A - Review of Completed Field Inspection - CCIR Questions 1-27",
            "MetaCat CCIR - Part B - Construction Activity & 3-Month Performance - CCIR Questions 28 - 40"
          ]
          break;
        default:
          options = [
            "General Information",
            "Administrative Requirements",
            "Permit Coverage",
            "Stormwater Pollution Prevention Plan (SWPPP)",
            "Site Conditions",
            "Site Status",
            "Stormwater Management Facilities (including Basins), Outfalls, and Discharges",
            "Erosion and Sediment Control BMPs"
          ]
      }
      if (element == undefined) {
        // var category_selects = $(document).find("select[id$='category']")
        // category_selects.each(function(index) {
        //   var selected_option = $(this)[0].value
        //   $(this).empty();
        //   for(var i=0; i< options.length; i++){
    		// 		$(this).append('<option value="' + options[i] + '">' + options[i] + '</option>');
    		// 	}
        //   $(this).filter(function(i, e) { return $(e).text() == selected_option})[0].prop('selected', true)
        // })
      } else {
        var select = $(element).find("select[id$='category']")
        var selected = select.find(':selected').text()
        select.empty();
        for(var i=0; i< options.length; i++){
          $(select).append('<option value="' + options[i] + '">' + options[i] + '</option>');
        }
        select.val(selected).attr('selected', 'selected')
      }
    };

    $("select[id$='response_type']").on("change", function(e) {
      t = $(e.target.closest('.nested-fields'));
      response_options = t.find('.template_inspection_report_template_questions_response_options');
      auto_populate_only = t.find('.template_inspection_report_template_questions_auto_populate_only');
      auto_populate_attribute = t.find('.template_inspection_report_template_questions_auto_populate_attribute');
      if (e.target.value == "Select" || e.target.value == "MultiSelect") {
        response_options.show()
        auto_populate_only.hide()
        auto_populate_attribute.hide()
      } else if (e.target.value == "Date") {
        response_options.hide()
        auto_populate_only.hide()
        auto_populate_attribute.hide()
      } else if (e.target.value == "Text") {
        response_options.hide()
        auto_populate_only.show()
        auto_populate_attribute.show()
      } else if (e.target.value == "None") {
        response_options.hide()
        auto_populate_only.show()
        auto_populate_attribute.show()
      } else {}
    });

    $("input[id$='has_subquestions']").on("change", function(e) {
      t = $(e.target.closest('.form-group'));
      n = t.next(".form-group");
      if ($(e.target).prop("checked")) {
        n.show();
      } else {
        n.hide();
      }
    });

    $("input[id$='has_action_items']").on("change", function(e) {
      t = $(e.target.closest('.form-group'));
      n = t.next(".form-group");
      if ($(e.target).prop("checked")) {
        n.show();
      } else {
        n.hide();
      }
    });

    $('.simple_form').on('cocoon:after-insert', function(e, insertedItem, originalEvent) {
      hideFields();
      $("select[id$='response_type']").on("change", function(e) {
        t = $(e.target.closest('.form-group'));
        n = t.next('.template_inspection_report_template_questions_response_options');
        if (e.target.value == "Select" || e.target.value == "MultiSelect") {
          n.show();
        } else {
          n.hide();
        }
      });

      $("input[id$='has_subquestions']").on("change", function(e) {
        t = $(e.target.closest('.form-group'));
        n = t.next(".form-group");
        if ($(e.target).prop("checked")) {
          n.show();
        } else {
          n.hide();
        }
      });
    });

    $('#template_inspection_report_inspection_type').on("change", function(e){
      updateTemplateCategoryOptions();
  	});

    $('.simple_form').on('cocoon:after-insert', function(e, insertedItem, originalEvent) {
  		updateTemplateCategoryOptions(insertedItem);
  	});

  };
});
