document.addEventListener("turbolinks:before-cache", function() {
  tables = $.fn.dataTable.fnTables(true);
  $(tables).each(function() {
    $(this).dataTable().fnDestroy();
  });
});

$(document).on("turbolinks:load", function () {
  $.extend( true, $.fn.dataTable.defaults, {
      stateSave: true,
      bDestroy: true,
      dom: 'lfrtip',
      lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]]
  });

  $('.datatable').each(function (){
    $(this).DataTable();
  });

  $('#dtClientUnapprovedInspections').DataTable({
    order: [
      [3, "desc"]
    ],
    lengthChange: false
  });
  $('#dtClientOpenCorrectiveActions').DataTable();
  $('#dtSiteSwppInspections').DataTable({
      lengthChange: false,
    });
  $('#dtSitePdfSwppInspections').DataTable({
      lengthChange: false,
      order: [
        [0, "desc"]
      ],
    });
  $('#dtSitePdfAepSwppInspections').DataTable();
  $('#dtSitePdfPaSwppInspections').DataTable({
    lengthChange: false,
    order: [
      [3, "desc"]
    ],
  });
  $('#dtSiteDustInspections').DataTable({
      lengthChange: false
    });
  $('#dtSiteSafetyInspections').DataTable({
      lengthChange: false
    });
  $('#dtSiteRainLog').DataTable({
      order: [
        [0, "desc"]
      ],
      dom: '<"d-flex justify-content-between"fBl>rtip',
      buttons: [
        {
          extend: 'copy',
          exportOptions: {
            columns: [0,1,2,3]
          }
        },
        {
          extend: 'csvHtml5',
          exportOptions: {
            columns: [0,1,2,3]
          }
        },
        {
          extend: 'pdf',
          exportOptions: {
            columns: [0,1,2,3]
          }
        },
        {
          extend: 'print',
          exportOptions: {
            columns: [0,1,2,3]
          }
        }
      ]
    });
  $('#dtSiteOpenActionItems').DataTable({
      order: [[ 3, "asc" ]],
      info: false,
      searching: false,
      lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]]
    });
  $('#dtSiteInProgressActionItems').DataTable({
    order: [[ 3, "asc" ]],
    info: false,
    searching: false,
    lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]]
  });
  $('#dtSiteUsers').DataTable({
    lengthChange: false
  });
  $('#dtBulkDownloadSummary').DataTable({
    searching: false,
    paging: false // false to disable pagination (or any other option)
  });
  $('#dtUsersIndexEarthworks').DataTable({
    searchPanes: {
      initCollapsed: true,
      cascadePanes: true,
      columns: [1,4],
      layout: 'columns-2'
    },
    dom: 'Plfrtip'
  });
  $('#dtUsersIndexSubscribers').DataTable({
    searchPanes: {
      initCollapsed: true,
      cascadePanes: true,
      columns: [4],
      layout: 'columns-4'
    },
    dom: 'Plfrtip'
  });
  $('#dtUsersImpersonate').DataTable();
  $('#dtProducts').DataTable({
    searchPanes: {
      initCollapsed: true,
      cascadePanes: true,
      columns: [1,2,3],
      layout: 'columns-3'
    },
    dom: 'Plfrtip',
    order: [
      [0, "desc"]
    ]
  });
  $('#dtProposalsIndexEarthworks').DataTable({
    searchPanes: {
      initCollapsed: true,
      cascadePanes: true,
      columns: [2,4,5,6,7],
      layout: 'columns-4'
    },
    dom: 'Plfrtip',
    order: [
      [0, "desc"]
    ]
  });
  $('#dtProposalsIndexClient').DataTable();
  $('#dtRainLog').DataTable({
    order: [
      [1, "desc"],
      [0, "asc"]
    ],
    dom: '<"d-flex justify-content-between"fBl>rtip',
    buttons: [
      'copy', 'csv', 'excel', 'pdf', 'print'
    ]
  });
  $('#dtSitesIndex').DataTable({
    order : [[0, "asc"]],
    dom: 'P<"d-flex flex-row-reverse justify-content-between mt-2"lf>rtip',
    searchPanes: {
      initCollapsed: true,
      cascadePanes: true,
      columns: [1,2,3,5],
      layout: 'columns-3'
    }
  });
  $('#dtCompanyNewUsers').DataTable({
    order : [[0, "asc"]],
    dom: 'P<"d-flex flex-row-reverse justify-content-between mt-2"lf>rtip',
    searchPanes: {
      initCollapsed: true,
      cascadePanes: true
    }
  });
  $('#dtClientSites').DataTable({
    lengthChange: false
  });
  $('#dtDashboardEarthworksDust').DataTable();
  $('#dtVehicleMaintenance').DataTable({
    order: [
      [ 1, "desc" ]
    ]
  });
  $('#dtVehicleInspections').DataTable({
    order: [
      [ 1, "desc" ]
    ]
  });
  $('#dtDashboardInProgressActionItems').DataTable({
    paging: false // false to disable pagination (or any other option)
  });
  $('#dtSiteDocuments').DataTable();
  $('#dtSitePermits').DataTable();
  $('#dtReportEditorSwpp').DataTable();
  $('#dtReportEditorDust').DataTable();
  $('#dtReportEditorSafety').DataTable();
  $('#dtReportEditorOther').DataTable();
  $('#dtProposalItems').DataTable({
    searchPanes: {
      initCollapsed: true,
      cascadePanes: true,
      columns: [1,2,3,4,5,6,8]
    },
    dom: 'P<"d-flex justify-content-between"fBl>rtip',
    buttons: [
      {
        extend: 'copy',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'csvHtml5',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'excelHtml5',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'print',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'colvis',
        text: "Show/Hide Columns",
      }
    ],
    order: [
      [0, "desc"]
    ]
  });
  $('#dtSiteContacts').DataTable({
    searching: false,
    paging: false // false to disable pagination (or any other option)
  });
  $('#dtSiteContactsERX').DataTable({
    searching: false,
    paging: false // false to disable pagination (or any other option)
  });
  $('#dtSiteTrades').DataTable({
    searching: false,
    paging: false // false to disable pagination (or any other option)
  });
  $('#dtActionItemDaysOpen').DataTable({
    dom: '<"d-flex justify-content-between"fBl>rtip',
    buttons: [
      'copy', 'csv', 'excel', 'pdf', 'print'
    ]
  });
  $('#dtActionItemReportBySite').DataTable({
    dom: '<"d-flex justify-content-between"fBl>rtip',
    buttons: [
      'csv', 'print',
      {
        extend: 'pdfHtml5',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'colvis',
        text: "Show/Hide Columns",
      }
    ]
  });
  $('#dtRainEventsReportBySite').DataTable({
    dom: '<"d-flex justify-content-between"fBl>rtip',
    buttons: [
      {
        extend: 'copy',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'csvHtml5',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'excelHtml5',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'print',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'colvis',
        text: "Show/Hide Columns",
      }
    ],
    rowsGroup: [0, 1],
    order : [
      [1, "desc"],
      [0, "asc"]
    ]
  });
  $('#dtQaqcInspectionsBySite').DataTable({
    dom: '<"d-flex justify-content-between"fBl>rtip',
    rowsGroup: [0,1,2],
    order : [
      [2, "desc"],
      [0, "asc"]
    ],
    buttons: [
      {
        extend: 'copy',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'csvHtml5',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'excelHtml5',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'print',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'colvis',
        text: "Show/Hide Columns",
      }
    ]
  });
  $('#dtQaqcTasksReport').DataTable({
    dom: '<"d-flex justify-content-between"fBl>rtip',
    buttons: [
      {
        extend: 'copy',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'csvHtml5',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'excelHtml5',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'print',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'colvis',
        text: "Show/Hide Columns",
      }
    ]
  });
  $('#dtRainLogTwelveMonthReport').DataTable({
    paging: false,
    order: []
  });
  $('#dtRainLogCustomReport').DataTable({
    dom: '<"d-flex justify-content-between"fBl>rtip',
    buttons: [
      'copy', 'csv', 'excel'
    ]
  });
  $('#dtQaTasks').DataTable({
    dom: '<"d-flex justify-content-between"fBl>rtip',
    buttons: [
      'copy', 'csv', 'excel'
    ],
    order : [
      [4, "asc"],
      [0, "asc"]
    ]
  });
  $('#dtMissingQaInspectionsBySite').DataTable({
    dom: '<"d-flex justify-content-between"fBl>rtip',
    buttons: [
      {
        extend: 'copy',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'csvHtml5',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'excelHtml5',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'print',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'colvis',
        text: "Show/Hide Columns",
      }
    ]
  });
  $('#dtUnapprovedInspectionsReport').DataTable({
    dom: '<"d-flex justify-content-between"fBl>rtip',
    rowsGroup: [0, 1, 2, 3],
    order : [
      [2, "desc"],
      [0, "asc"]
    ],
    buttons: [
      'pageLength',
      {
        extend: 'copy',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'csvHtml5',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'excelHtml5',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'print',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'colvis',
        text: "Show/Hide Columns",
      }
    ],
    lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]]
  });
  $('#dtSiteActionItemReport').DataTable({
    dom: 'PBfrtip',
    searchPanes: {
      initCollapsed: true,
      cascadePanes: true,
      columns: [2,3],
      layout: 'columns-2'
    },
    buttons: [
      'pageLength',
      {
        extend: 'copy',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'csvHtml5',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'excelHtml5',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'print',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'colvis',
        text: "Show/Hide Columns",
      }
    ],
    lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
  });
  $('#dtSiteDeficiencies').DataTable({
  });
  $('#dtEmailList').DataTable({
    dom: '<"d-flex justify-content-between"fBl>rtip',
    buttons: [
      'copy', 'csv', 'excel', 'pdf', 'print'
    ]
  });
  $('#dtWorkItemReport').DataTable({
    dom: '<"d-flex justify-content-between"fBl>rtip',
    buttons: [
      {
        extend: 'copy',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'csvHtml5',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'print',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'colvis',
        text: "Show/Hide Columns",
      }       
    ]
  });
  $('#dtRainEventReport').DataTable({
    dom: '<"d-flex justify-content-between"fBl>rtip',
    buttons: [
      {
        extend: 'copy',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'csvHtml5',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'print',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'colvis',
        text: "Show/Hide Columns",
      }       
    ]
  });
  $('#dtGradingLog').DataTable({
    dom: '<"d-flex justify-content-between"fBl>rtip',
    buttons: [
      'copy', 'csv', 'excel',
      {
        extend: 'print',
        exportOptions: {
          columns: [0,1,2,3,4,5,6]
        }
      },
      {
        extend: 'pdf',
        exportOptions: {
          columns: [0,1,2,3,4,5,6]
        },
        orientation: 'landscape'
      }
    ]
  });
  $('#dtCompanyUsers').DataTable({
    dom: '<"d-flex justify-content-between"fBl>rtip',
    buttons: [
      'copy', 'csv', 'excel', 'pdf', 'print'
    ]
  });
  $('#dtCompanySites').DataTable({
    dom: '<"d-flex justify-content-between"fBl>rtip',
    buttons: [
      'copy', 'csv', 'excel', 'pdf', 'print'
    ],
    order: [
      [0, "asc"]
    ]
  });
  $('#dtUnaffiliatedSites').DataTable();
  $('#dtUnaffiliatedUsers').DataTable();
  $('#dtCompanies').DataTable();
  $('#dtDivisionSites').DataTable();
  $('#dtDivisionUsers').DataTable();
  $('#dtVehiclesIndex').DataTable({
    dom: '<"d-flex justify-content-between"fBl>rtip',
    buttons: [
      'csv', 'print',
      {
        extend: 'pdfHtml5',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'colvis',
        text: "Show/Hide Columns",
      }
    ]
  });
  $('#dtVehicleReports').DataTable({
    dom: '<"d-flex justify-content-between"fBl>rtip',
    buttons: [
      'csv', 'print',
      {
        extend: 'pdfHtml5',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'colvis',
        text: "Show/Hide Columns",
      }
    ]
  });
  $('#dtInspections').DataTable({
    order: [
      [0, "desc"]
    ]
  });
  $('#dtFormsIndex').DataTable({
  });
  $('#dtSiteInspections').DataTable({
    paging: false,
    info: false,
    searching: false,
    order: [
      [0,"desc"]
    ]
  });
  $('#dtSiteForms').DataTable({
    paging: false,
    info: false,
    searching: false,
    order: [
      [0,"desc"]
    ]
  });
  $('#dtSiteInspectionsShow').DataTable({
    paging: false,
    info: false,
    searching: false,
    order: [
      [0,"desc"]
    ]
  });
  $('#dtPendingSitesProposals').DataTable({
    order : [
      [1, "desc"]
    ]
  });
  $('#dtOverdueInspectionsReport').DataTable({
    order : [
      [2, "asc"]
    ],
    rowGroup: {
      dataSrc: 2
    }
  });
  $('#dtTemplateInspectionReports').DataTable();
  $('#dtMapsIndex').DataTable();
  $('#dtLotsIndex').DataTable();
  $('#dtGradingIndex').DataTable();
  $('#dtPermitUploadsIndex').DataTable();
  $('#dtQcInspections').DataTable();
  $('#dtInvalidPermitsReport').DataTable({
    dom: 'PBlfrtip',
    buttons: [
      'csv', 'print',
      {
        extend: 'pdfHtml5',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'colvis',
        text: "Show/Hide Columns",
      }
    ],
    searchPanes: {
      initCollapsed: true,
      cascadePanes: true,
      columns: [1,2,3,4,5,6],
      layout: 'columns-4'
    },
    order: [
      [0, "desc"]
    ]
    
  });
  $('#dtPermitSummaryReport').DataTable({
    dom: 'PBlfrtip',
    buttons: [
      'csv', 'print',
      {
        extend: 'pdfHtml5',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'colvis',
        text: "Show/Hide Columns",
      }
    ],
    searchPanes: {
      initCollapsed: true,
      cascadePanes: true,
      columns: [0,1,2,3,4,6,9],
      layout: 'columns-4'
    },
    order: [
      [0, "desc"]
    ]
    
  });
  $('#dtRegulatoryInspectionsReport').DataTable({
    dom: 'P<"d-flex justify-content-between"fBl>rtip',
    rowsGroup: [0, 1, 2, 3],
    order : [
      [2, "desc"],
      [0, "asc"]
    ],
    buttons: [
      'pageLength',
      {
        extend: 'copy',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'csvHtml5',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'excelHtml5',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'print',
        exportOptions: {
          columns: ':visible'
        }
      },
      {
        extend: 'colvis',
        text: "Show/Hide Columns",
      }
    ],
    lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
    searchPanes: {
      initCollapsed: true,
      cascadePanes: true,
      columns: [0,1,3,5,7],
    }
  });
});
