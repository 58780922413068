$( document ).on("turbolinks:load", function() {
  var anyEmail = $('#preference_receive_any_email').prop('checked')

  if(anyEmail) {
    $("#preference_receive_daily_summary_email").prop("disabled", false);
    $("#preference_receive_daily_unapproved_summary").prop("disabled", false);
    $("#preference_receive_daily_action_item_summary").prop("disabled", false);
    $("#preference_receive_individual_site_notifications").prop("disabled", false);
    $("#preference_receive_rain_log_email").prop("disabled", false);
  }
  else {
    $("#preference_receive_daily_summary_email").prop("disabled", true);
    $("#preference_receive_daily_summary_email").prop("checked", false);
    $("#preference_receive_daily_unapproved_summary").prop("disabled", true);
    $("#preference_receive_daily_unapproved_summary").prop("checked", false);
    $("#preference_receive_daily_action_item_summary").prop("disabled", true);
    $("#preference_receive_daily_action_item_summary").prop("checked", false);
    $("#preference_receive_individual_site_notifications").prop("disabled", true);
    $("#preference_receive_individual_site_notifications").prop("checked", false);
    $("#preference_receive_rain_log_email").prop("disabled", true);
    $("#preference_receive_rain_log_email").prop("checked", false);
  }

  $('#preference_receive_any_email').click(function(){
    var isDisabled = $('#preference_receive_any_email').prop('checked')
    if(isDisabled) {
      $("#preference_receive_daily_summary_email").prop("disabled", false);
      $("#preference_receive_daily_unapproved_summary").prop("disabled", false);
      $("#preference_receive_daily_action_item_summary").prop("disabled", false);
      $("#preference_receive_individual_site_notifications").prop("disabled", false);
      $("#preference_receive_rain_log_email").prop("disabled", false);
    }
    else {
      $("#preference_receive_daily_summary_email").prop("disabled", true);
      $("#preference_receive_daily_summary_email").prop("checked", false);
      $("#preference_receive_daily_unapproved_summary").prop("disabled", true);
      $("#preference_receive_daily_unapproved_summary").prop("checked", false);
      $("#preference_receive_daily_action_item_summary").prop("disabled", true);
      $("#preference_receive_daily_action_item_summary").prop("checked", false);
      $("#preference_receive_individual_site_notifications").prop("disabled", true);
      $("#preference_receive_individual_site_notifications").prop("checked", false);
      $("#preference_receive_rain_log_email").prop("disabled", true);
      $("#preference_receive_rain_log_email").prop("checked", false);
    }
  });

  if ( document.querySelector('body.user_sites.new') ) {
    // https://stackoverflow.com/questions/36217098/checkboxes-only-work-on-first-page-datatables-rails
    var user_assignments_table = $('#dtUserSites').DataTable({
      columns: [
				{data: "id"},
	      {data: "email"},
        {data: "first_name"},
        {data: "last_name"}
	    ],
      // initComplete: function(settings){
      //    var api = this.api();

      //    api.cells(
      //       api.rows(function(idx, data, node){
      //         return (data["assigned"] === "true") ? true : false;
      //       }).indexes(),
      //       0
      //    ).checkboxes.select();
      // },
      columnDefs: [
         {
          targets: 0,
          checkboxes: {
              selectRow: true
            }
         }
        //  {
 				// 	targets: 5,
 				// 	visible: false
 				// },
      ],
      select: 'multi',
			order: [
				[3, "asc"]
			],
      stateSave: false
    });

    // Handle form submission event
		$('form.edit_site').on('submit', function(e){
		  var form = this;
		  var rows_selected = user_assignments_table.column(0).checkboxes.selected();
		  // Iterate over all selected checkboxes
		  $.each(rows_selected, function(index, rowId){
		     // Create a hidden element
		     $(form).append(
		         $('<input>')
		            .attr('type', 'hidden')
		            .attr('name', 'site[user_ids][]')
		            .val(rowId)
		     );
		  });
		});
  }

  if ( document.querySelector('body.users.show') ) {

    var site_settings_table = $('#dtUserShow').DataTable({
      columns: [
				{data: "id"},
	      {data: "site_name"},
        {data: "company_name"},
	      {data: "state"},
        {data: "approver"},
        {data: "report_emails"},
	      {data: "proposal_emails"},
        {data: "regulatory_notifications"},
        {data: "summary_emails"}
	    ],
      columnDefs: [
         {
          targets: 0,
          checkboxes: {
              selectRow: true
            }
         }
      ],
      select: 'multi',
			order: [
				[1, "asc"]
			]
    });

    $('form#user_notifications').on('submit', function(e){
		  var form = this;
		  var rows_selected = site_settings_table.column(0).checkboxes.selected();
		  // Iterate over all selected checkboxes
		  $.each(rows_selected, function(index, rowId){
		     // Create a hidden element
		     $(form).append(
		         $('<input>')
		            .attr('type', 'hidden')
		            .attr('name', 'user_site_ids[]')
		            .val(rowId)
		     );
		  });
		});

    // https://stackoverflow.com/questions/36217098/checkboxes-only-work-on-first-page-datatables-rails
    var site_assignments_table = $('#dtUserSiteAssignments').DataTable({
      dom: 'Plfrtip',
      columns: [
				{data: "id"},
	      {data: "site_name"},
        {data: "site_status"},
        {data: "company_name"},
        {data: "assigned"}
	    ],
      initComplete: function(settings){
         var api = this.api();

         api.cells(
            api.rows(function(idx, data, node){
              return (data["assigned"] === "true") ? true : false;
            }).indexes(),
            0
         ).checkboxes.select();
      },
      columnDefs: [
         {
          targets: 0,
          checkboxes: {
              selectRow: true
            }
         },
         {
            targets: 4,
            visible: false
          }
      ],
      select: 'multi',
			order: [
				[1, "asc"]
			],
      searchPanes: {
        initCollapsed: true,
        cascadePanes: true,
        columns: [2,3]
      }
    });

    // Handle form submission event
		$('form.edit_user').on('submit', function(e){
		  var form = this;
		  var rows_selected = site_assignments_table.column(0).checkboxes.selected();
      if (rows_selected.length > 0) {
        // Iterate over all selected checkboxes
        $.each(rows_selected, function(index, rowId){
          // Create a hidden element
          $(form).append(
              $('<input>')
                .attr('type', 'hidden')
                .attr('name', 'user[site_ids][]')
                .val(rowId)
          );
        });
      } else {
        // Create a hidden element
        $(form).append(
          $('<input>')
            .attr('type', 'hidden')
            .attr('name', 'user[site_ids][]')
            .val("")
      );
      }
		  
		});

	};

});
